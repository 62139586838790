import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import GalleryHeader from "@components/klips/gallery-header"
import Layout from "@components/layout"
import { KlipsEOPCta } from "@components/cta/eop/klips"
import { HTMLWrapper } from "@components/common/html-wrapper"
import Div from "@components/elements/div"
import { useTheme } from "styled-components"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import { GalleryGrid } from "@components/gallery/grid"
import Anchor from "@components/elements/anchor"

const KlipIntegrationTemplate = ({ pageContext, data }) => {
  const service = data.service
  const { color } = useTheme()

  return (
    <Layout
      seo={{
        title: `${service.name} Dashboard - Klips Integrations`,
        description:
          "Want to easily connect your data to a dashboard? Klipfolio has over 250 prebuilt data connectors! Learn how you can connect your favorite services with Klipfolio.",
      }}
      fullWidth={true}
    >
      <article>
        <GalleryHeader
          name={service.name}
          subtitleHTML={service.description}
          newsletterCta
          shadow={false}
          kpi
          links={[
            { text: "Integrations", url: "/integrations" },
            {
              text: service.name,
            },
          ]}
        />
        <Div textContainer margin="0 auto 2rem">
          <HTMLWrapper
            styles={{
              paragraph: {
                color: color.blue600,
              },
              heading: {
                color: color.blue600,
              },
              link: {
                color: color.blue400,
              },
            }}
            html={service.body}
            images={service.body_images}
            margin="0 0 3rem"
          />
        </Div>

        {pageContext.klips && (
          <>
            <Flex textContainer gap="1rem" margin="0 auto 4rem">
              <Heading as="h2" color={color.blue600} margin="0 0 1rem">
                Use pre-built {service.name} metrics
              </Heading>
              <Paragraph color={color.blue600}>
                Assemble a {service.name} dashboard by selecting pre-built
                metrics&nbsp;from the Klip Gallery.
              </Paragraph>
            </Flex>
            <Div container margin="0 auto 4rem">
              <GalleryGrid klips={pageContext.klips} />
            </Div>
          </>
        )}
        <Flex textContainer gap="1rem" margin="0 auto 6rem">
          <Heading as="h2" color={color.blue600} margin="0 0 1rem">
            Use the Connector Gallery to create a custom {service.name}
            &nbsp;dashboard
          </Heading>
          <Paragraph color={color.blue600}>
            With our Connector Gallery, you can create custom API queries to
            pull in data from your {service.name} account. Building custom
            queries allows you to specify the metrics and time periods that you
            want to track.
          </Paragraph>
          <Paragraph color={color.blue600}>
            You can use custom queries to build out visualizations in Klipfolio.
            You can also combine your {service.name} data with other relevant
            data to create your custom dashboard.
          </Paragraph>
          <Paragraph color={color.blue600}>
            Learn more about working with the {service.name} API and how you can
            start{" "}
            <Anchor color={color.blue400} link={service.docs}>
              building your own {service.name} queries
            </Anchor>{" "}
            in Klipfolio.
          </Paragraph>
        </Flex>
        <KlipsEOPCta />
      </article>
    </Layout>
  )
}

KlipIntegrationTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default KlipIntegrationTemplate

export const pageQuery = graphql`
  query KlipIntegrationTemplate($id: String!) {
    service: galleryServices(id: { eq: $id }) {
      name
      logo {
        ...imageData
      }
      description
      body
      body_images {
        ...imageData
      }
      docs
      slug
    }
  }
`
